import { useState, useEffect } from 'react';
import api from 'api';
import { useCurrentUser } from 'state/current-user/current-user.thunk';

const useGoals = (selectedYear) => {
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const currentUser = useCurrentUser();

  useEffect(() => {
    const getGoals = async () => {
      setLoading(true);
      try {
        const res = await api.getAllGoals({
          userId: currentUser.data.oktaUserId,
          clientId: currentUser.data.client.id,
          year: selectedYear,
        });

        if (res?.data?.content) {
          setGoals(res.data.content);
        } else {
          setGoals([]);
        }
      } catch (error) {
        console.error('An error occurred while fetching goals', error);
      } finally {
        setLoading(false);
      }
    };

    getGoals();
  }, [selectedYear]);

  return { loading, goals };
};

export default useGoals;
