import React, { useContext } from 'react';
import { SecureRoute } from '@okta/okta-react';
import { Redirect, Switch } from 'react-router';
import classNames from 'classnames';

//base pages
import Home from 'containers/user/home/home';
import Reports from 'containers/user/reports/reports';
import Profile from 'containers/admin/profile/profile';
import Files from 'containers/user/files/files';
import ProfileForUserMode from 'containers/user/profile/profile'; //untill redesign for user mode is ready
import Learn from 'containers/user/learn/learn';
import ReportPage from 'containers/user/reports/components/report-page/report-page';

//Learn sub pages
import GetStartedGuide from 'containers/user/learn/components/get-started-guide/get-started-guide';

import Content from 'containers/user/learn/containers/content/content';
import Articles from 'containers/user/learn/containers/content/articles/articles';
import Article from 'containers/user/learn/containers/content/articles/article/article';
import Downloads from 'containers/user/learn/containers/content/downloads/downloads';
import Download from 'containers/user/learn/containers/content/downloads/download/download';
import Guides from 'containers/user/learn/containers/content/guides/guides';
import Guide from 'containers/user/learn/containers/content/guides/guide/guide';
import Webinars from 'containers/user/learn/containers/content/webinars/webinars';
import Webinar from 'containers/user/learn/containers/content/webinars/webinar/webinar';
import Courses from 'containers/user/learn/containers/content/cources/courses';
import CourseOverview from 'containers/user/learn/containers/content/cources/cource-overview/course-overview';

import ClientsAdminPage from 'containers/admin/clients/clients-admin-page';
import ClientDetails from 'containers/admin/clients/client/client-details';
import ClientProfile from 'containers/admin/clients/client/client-profile/client-profile';

import UsersAdminPage from 'containers/admin/users/users-admin-page';
import UserPage from 'containers/admin/users/user/user-page/user-page';
import UserProfile from 'containers/admin/users/user/user-profile/user-profile';

import FilePage from 'containers/admin/clients/client/tabs-components/files-tab/file/file-page/file-page';
import File from 'containers/admin/clients/client/tabs-components/files-tab/file/file';
import CourseDetails from 'containers/user/learn/containers/content/cources/course-details/course-details';

import GoalsManager from 'containers/admin/clients/client/tabs-components/goals-tab/goals-manager/goals-manager';

import MyGoals from 'containers/user/goals/goals';

import { useImpersonation } from 'state/impersonation/impersonation.thunk';

import { FeatureFlagsContext } from 'app';

const MainAuthenticated = ({ user }) => {
  const isAdmin = user?.data && (user?.data?.fdUser || user?.data?.clientAdmin);

  const mvAnalyst = user?.data?.fdAnalyst;
  const { featureFlags } = useContext(FeatureFlagsContext);

  const { impersonatingUser } = useImpersonation();

  return (
    <main
      className={classNames(
        'flex flex-col flex-auto mt-14 items-center w-full',
        {
          'mobile:!mt-[97px] tablet:!mt-[104px]': !isAdmin && impersonatingUser,
        }
      )}
    >
      <Switch>
        <SecureRoute
          path="/"
          exact
          render={() =>
            // TODO where should superadmins go...
            isAdmin ? <Redirect to="/admin/clients" /> : <Redirect to="/home" />
          }
        />
        <SecureRoute
          key="profile"
          exact
          path="/admin/profile"
          render={() => <Profile />}
        />
        {!mvAnalyst && [
          <SecureRoute
            key="newFilesForClient"
            exact
            path="/admin/clients/:clientId/new-file"
            render={() => <File />}
          />,
        ]}
        {featureFlags['tab-goals'] && !isAdmin && (
          <SecureRoute
            key="my-goals"
            exact
            path="/goals"
            render={() => <MyGoals />}
          />
        )}
        {!isAdmin && [
          // TODO only if you have a clientId?
          <SecureRoute key="home" exact path="/home" render={() => <Home />} />,
          <SecureRoute
            key="report"
            path="/reports/:id/report-details"
            render={({ match }) => <ReportPage reportId={match.params.id} />}
          />,
          <SecureRoute
            key="reports"
            path="/reports/:reportType?"
            render={() => <Reports />}
          />,
          <SecureRoute
            key="reportsPage"
            path="/reports"
            render={() => <Reports />}
          />,
          <SecureRoute
            key="profile"
            exact
            path="/profile"
            render={() => <ProfileForUserMode />} //until we redesign portal for user mode too
          />,
          <SecureRoute
            key="learn"
            path="/learn"
            exact
            render={() => <Learn />}
          />,
          <SecureRoute
            key="learnGetStartedGuide"
            path="/learn/get-started-guide"
            render={() => <GetStartedGuide />}
          />,
          //Categories pages
          <SecureRoute
            key="learnCategories"
            exact
            path="/learn/categories"
            render={() => <div>Categories Page</div>}
          />,
          <SecureRoute
            key="learnCategoriesDataAnalysis"
            exact
            path="/learn/categories/data-analysis"
            render={() => <div>Data Analysis Fundamentals</div>}
          />,
          <SecureRoute
            key="learnCategoriesOptimizing"
            exact
            path="/learn/categories/optimizing-performance"
            render={() => <div>Optimizing Performance</div>}
          />,
          <SecureRoute
            key="learnCategoriesAdvancedAnalytics"
            exact
            path="learnCategories"
            render={() => <div>Advanced Analytics Techniques</div>}
          />,
          <SecureRoute
            key="learnCategoriesVisualization"
            exact
            path="/learn/categories/data-visualization"
            render={() => <div>Data Visualization and Reporting</div>}
          />,
          <SecureRoute
            key="learnCategoriesCollaboration"
            exact
            path="/learn/categories/collaboration-and-workflow"
            render={() => <div>Collaboration and Workflow</div>}
          />,
          //Content pages
          <SecureRoute
            key="learnContent"
            exact
            path="/learn/content"
            render={() => <Content />}
          />,
          <SecureRoute
            key="learnContentArticles"
            exact
            path="/learn/content/articles"
            render={() => <Articles />}
          />,
          <SecureRoute
            key="learnContentArticle"
            exact
            path="/learn/content/articles/:id"
            render={({ match }) => <Article articleId={match.params.id} />}
          />,
          <SecureRoute
            key="learnContentGuides"
            exact
            path="/learn/content/guides"
            render={() => <Guides />}
          />,
          <SecureRoute
            key="learnContentGuide"
            exact
            path="/learn/content/guides/:id"
            render={({ match }) => <Guide guideId={match.params.id} />}
          />,
          <SecureRoute
            key="learnContentDownloads"
            exact
            path="/learn/content/downloads"
            render={() => <Downloads />}
          />,
          <SecureRoute
            key="learnContentDownload"
            exact
            path="/learn/content/downloads/:id"
            render={({ match }) => <Download downloadId={match.params.id} />}
          />,
          <SecureRoute
            key="learnContentWebinars"
            exact
            path="/learn/content/webinars"
            render={() => <Webinars />}
          />,
          <SecureRoute
            key="learnContentWebinar"
            exact
            path="/learn/content/webinars/:id"
            render={({ match }) => <Webinar webinarId={match.params.id} />}
          />,
          <SecureRoute
            key="learnContentGlossary"
            exact
            path="/learn/content/glossary"
            render={() => <div>Glossary</div>}
          />,
          //Courses pages
          <SecureRoute
            key="learnCourses"
            exact
            path="/learn/courses"
            render={() => <Courses />}
          />,
          <SecureRoute
            key="learnCourse"
            exact
            path="/learn/course/:id"
            render={({ match }) => (
              <CourseOverview courseId={match.params.id} />
            )}
          />,
          <SecureRoute
            key="learnCourseDetails"
            exact
            path="/learn/courses/:courseId/lesson/:lessonId"
            render={() => <CourseDetails />}
          />,
          <SecureRoute
            key="files"
            exact
            path="/files"
            render={() => <Files />}
          />,
        ]}
        {isAdmin && [
          <SecureRoute
            key="clients"
            exact
            path="/admin/clients"
            render={({ location }) => {
              const state = location?.state;

              return (
                <ClientsAdminPage
                  notificationParams={state?.notificationParams}
                />
              );
            }}
          />,
          <SecureRoute
            key="profilePage"
            exact
            path="/admin/clients/new"
            render={() => <ClientProfile pageName="Add new client" />}
          />,
          <SecureRoute
            key="userInClient"
            exact
            path="/admin/clients/:clientId/users/:id"
            render={({ match, location }) => {
              const isValidId = !isNaN(match.params.id);
              const state = location?.state;

              return isValidId ? (
                <Redirect
                  to={`/admin/clients/${match.params.clientId}/users/${match.params.id}/profile`}
                />
              ) : (
                <UserPage
                  clientId={match.params.clientId}
                  notificationParams={state?.notificationParams}
                />
              );
            }}
          />,
          <SecureRoute
            exact
            key="fileDetailsInClientDetailsPage"
            path="/admin/clients/:clientId/files/:fileId"
            render={({ match, location }) => {
              const state = location?.state;

              return (
                <FilePage
                  clientId={match.params.clientId}
                  fileId={match.params.fileId}
                  notificationParams={state?.notificationParams}
                />
              );
            }}
          />,
          <SecureRoute
            key="goalManager"
            exact
            path="/admin/clients/:clientId/goals/:year/:goalType/:goalCategory/:goalId?"
            render={() => <GoalsManager />}
          />,
          <SecureRoute
            exact
            key="userInClientDetailsPage"
            path="/admin/clients/:clientId/users/:id/profile"
            render={({ location }) => {
              const state = location?.state;

              return (
                <UserPage notificationParams={state?.notificationParams} />
              );
            }}
          />,
          <SecureRoute
            key="clientIdProfile"
            exact
            path="/admin/clients/:id"
            render={({ match }) => <Redirect to={`${match.params.id}/users`} />}
          />,
          <SecureRoute
            key="clientId"
            path="/admin/clients/:id"
            render={() => <ClientDetails />}
          />,
        ]}
        {/* Super admins can see the Users section */}
        {isAdmin && [
          <SecureRoute
            key="users"
            path="/admin/users"
            exact
            render={({ location }) => {
              const state = location?.state;

              return (
                <UsersAdminPage
                  notificationParams={state?.notificationParams}
                />
              );
            }}
          />,
          <SecureRoute
            key="newUser"
            exact
            path="/admin/users/new"
            render={({ location }) => {
              const state = location?.state;

              return (
                <UserProfile notificationParams={state?.notificationParams} />
              );
            }}
          />,
          <SecureRoute
            key="userId"
            exact
            path="/admin/users/:id"
            render={({ match }) => (
              <Redirect to={`${match.params.id}/profile`} />
            )}
          />,
          <SecureRoute
            key="userIdProfile"
            path="/admin/users/:id/profile"
            render={({ location }) => {
              const state = location?.state;

              return (
                <UserPage notificationParams={state?.notificationParams} />
              );
            }}
          />,
        ]}
        {/* Fallback 404 routes */}
        <SecureRoute
          key="fallback"
          path="/"
          render={() => <Redirect to={isAdmin ? '/admin/clients' : '/home'} />}
        />
        ,
        {isAdmin && [
          <SecureRoute
            key="users"
            path="/admin/users"
            exact
            render={({ location }) => {
              const state = location?.state;

              return (
                <UsersAdminPage
                  notificationParams={state?.notificationParams}
                />
              );
            }}
          />,
          <SecureRoute
            key="newUser"
            exact
            path="/admin/users/new"
            render={({ location }) => {
              const state = location?.state;

              return (
                <UserProfile notificationParams={state?.notificationParams} />
              );
            }}
          />,
          <SecureRoute
            key="userId"
            exact
            path="/admin/users/:id"
            render={({ match }) => (
              <Redirect to={`${match.params.id}/profile`} />
            )}
          />,
          <SecureRoute
            key="userIdProfile"
            path="/admin/users/:id/profile"
            render={({ location }) => {
              const state = location?.state;

              return (
                <UserPage notificationParams={state?.notificationParams} />
              );
            }}
          />,
        ]}
        {/* Fallback 404 routes */}
        <SecureRoute
          key="fallback"
          path="/"
          render={() => <Redirect to={isAdmin ? '/admin/clients' : '/home'} />}
        />
        ,
      </Switch>
    </main>
  );
};

export default MainAuthenticated;
