import React, { useState, useContext } from 'react';
import uniqid from 'uniqid';
import classNames from 'classnames';

import HeaderItem from 'components/shared/others/header/header-item/header-item';

import ReportsPopover from 'containers/user/reports/components/reports-popover/reports-popover';
import LearnPopover from 'components/shared/others/header/learn-popover/learn-popover-partial';
import LearnMobileSection from 'components/shared/others/header/learn-mobile-section/learn-mobile-section';

import Overlay from 'components/shared/others/overlay/overlay';

import { HeaderContext } from 'components/shared/others/header/header';

import { history } from 'state/history';

import { FeatureFlagsContext } from 'app';

const MainNav = ({
  isMobileMenuOpen = false,
  setIsMobileMenuOpen,
  isMobileMenu = false,
  menuItems,
}) => {
  const [reportsPopoverIsShown, setReportsPopoverIsShown] = useState(false);
  const [learnPopoverIsShown, setLearnPopoverIsShown] = useState(false);

  const { featureFlags } = useContext(FeatureFlagsContext);

  const filteredMenuItems = menuItems.filter(
    (item) => !(item.name === 'Goals' && !featureFlags['tab-goals'])
  );

  const { isLearnSectionOpen, setIsLearnSectionOpen } =
    useContext(HeaderContext);

  const mainStyles = `mobile:bg-white mobile:auto-rows-max mobile:absolute mobile:top-12 tablet:top-14 mobile:z-[-1] mobile:h-[100vh] mobile:w-available xs:auto-rows-auto xs:h-auto xs:w-fit xs:static xs:z-0 xs:top-0 grid tablet:col-start-2 xs:justify-self-start xs:grid xs:grid-cols-${menuItems.length} xs:grid-flow-col`;

  const onMenuItemClick = ({ isLearn, isReports, route }) => {
    // if Reports menu item is clicked and it's desktop - open Reports popover
    if (isReports && !isMobileMenu) {
      learnPopoverIsShown && setLearnPopoverIsShown(false);
      setReportsPopoverIsShown(!reportsPopoverIsShown);
      // if Learn menu item is clicked
    } else if (isLearn) {
      // it's desktop - open Learn popover
      if (!isMobileMenu) {
        reportsPopoverIsShown && setReportsPopoverIsShown(false);
        setLearnPopoverIsShown(!learnPopoverIsShown);
      } else {
        // it's tablet and less - open Learn Menu Section
        setIsLearnSectionOpen(!isLearnSectionOpen);
      }
    } else {
      // all the rest - just redirect to the page
      setIsMobileMenuOpen(false);
      history.push(route);
    }
  };

  const getLearnSection = (isLearn) => {
    const traininPopoverSouldBeShown = learnPopoverIsShown && !isMobileMenu;

    return traininPopoverSouldBeShown ? (
      <LearnPopover />
    ) : (
      isLearn && isLearnSectionOpen && <LearnMobileSection />
    );
  };

  const getOverlay = () => {
    const overlaySouldBeShown =
      isMobileMenuOpen || learnPopoverIsShown || reportsPopoverIsShown;

    return (
      overlaySouldBeShown && (
        <Overlay
          closeOnClick={() => {
            setIsMobileMenuOpen(false);
            setReportsPopoverIsShown(false);
            setLearnPopoverIsShown(false);
          }}
        />
      )
    );
  };

  return (
    <div className={mainStyles}>
      {filteredMenuItems.map(({ name, route, icon, iconAfter }, ind) => {
        const isLearn = name === 'Learn';

        const isReports = name === 'Reports';
        const isIconRotated =
          (isReports && reportsPopoverIsShown) ||
          (isLearn && learnPopoverIsShown) ||
          isLearnSectionOpen;

        const isActive = window.location.pathname.includes(route)
          ? 'mobile:text-indigo-02 xs:!border-yellow-03 '
          : 'text-gray-04';

        return (
          <div
            className={classNames(
              `flex flex-col mobile:border-b-[1px] mobile:py-3 xs:px-2 xs:justify-center xs:border-b-[3px] xs:border-transparent ${isActive}`,
              { 'xs:ml-2': ind === 0, relative: isLearn }
            )}
            key={uniqid()}
          >
            <HeaderItem
              onClick={() => onMenuItemClick({ isLearn, isReports, route })}
              rotateClass={isIconRotated && 'rotate-180'}
              iconBefore={icon}
              iconAfter={isMobileMenu && isReports ? null : iconAfter}
            >
              {name}
            </HeaderItem>
            {/* Learn section view according to breakpoints */}
            {isLearn && getLearnSection(isLearn)}
            {/* Reports popover */}
            {isReports && !isMobileMenu && reportsPopoverIsShown && (
              <ReportsPopover />
            )}
            {/* Overlay in case any of popovers is open or it's tablet mode */}
          </div>
        );
      })}
      {getOverlay()}
    </div>
  );
};

export default MainNav;
