import React, { useState } from 'react';

import ResponsiveWrapper from 'components/shared/others/responsive-wrapper/responsive-wrapper';
import Heading from 'components/partials/heading/heading';
import Loader from 'components/partials/loader/loader';
import { CustomSelect as Select } from 'components/partials/custom-select/custom-select';

import GoalItem from 'containers/user/goals/goal-item';
import GoalContactItem from 'containers/user/goals/goal-contact-item';

import { generateYearOptions } from 'containers/admin/clients/client/tabs-components/goals-tab/goals-matrix/goals-matrix-filters/goals-matrix-filters';

import { getCurrentYear } from 'containers/admin/clients/client/client-details';
import EmptySpaceContent from 'components/partials/empty-space-content/empty-space-content';
import useGoals from 'containers/user/goals/use-goal';

import Icons from 'assets/icons';

const MyGoals = () => {
  const currentYear = getCurrentYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const years = generateYearOptions();

  const { loading, goals } = useGoals(selectedYear);

  const renderGoalsList = () => (
    <div className="flex flex-wrap gap-4 mt-4">
      {goals.map((goal) => (
        <GoalItem key={goal.id} goal={goal} />
      ))}
      <GoalContactItem />
    </div>
  );

  const renderEmptyState = () => {
    const extraProps =
      selectedYear >= currentYear
        ? {
            linkShow: true,
            linkText: 'Contact Us',
            onClick: () => {
              console.log('Contact Us');
            },
          }
        : {};

    return (
      <EmptySpaceContent
        image={<Icons.EmptyImageAll customClass="w-52 h-52" />}
        text="No goals have been defined for the selected period yet."
        {...extraProps}
      />
    );
  };

  return (
    <ResponsiveWrapper customClass="mt-8 xs:max-w-[900px]">
      <Loader loading={loading} fullScreen={false} />
      <div className="flex items-center mb-6 gap-4">
        <Heading variant="h2">My Goals</Heading>
        <Select
          options={years}
          value={years.find((year) => year.value === selectedYear)}
          onChange={(option) => setSelectedYear(option.value)}
          customSelectWrapperClass="!w-28 mr-4"
          extraBasicStyles={{ padding: 0, height: '2' }}
        />
      </div>

      {!goals.length ? renderEmptyState() : renderGoalsList()}
    </ResponsiveWrapper>
  );
};

export default MyGoals;
