const DEFAULT_COLUMN_WIDTH = 288;
const DEFAULT_MAX_COLUMNS = 4;

/**
 * Generates a layout map for positioning widgets in a masonry grid.
 *
 * @param {Array<Object>} widgets - The array of widgets to be positioned in the grid.
 * Each widget should have `colSpan` and `rowSpan` properties to specify the widget's
 * width and height in grid units. `rowSpan` can be an object to support breakpoints.
 * @param {number} columnCount - The total number of columns available in the grid.
 * @param {string} activeBreakpoint - The current active breakpoint to determine rowSpan.
 * @returns {Object} An object containing:
 *   - `layoutMap` {Object}: A map of positions with keys as row and column indices
 *     (e.g., '0,0') and values as the widget index.
 *   - `rowCount` {number}: The total number of rows used to fit all widgets.
 */
export const generateLayoutMap = (widgets, columnCount, activeBreakpoint) => {
  const layoutMap = {};
  let maxRow = 0;

  /**
   * Checks if a widget can be placed at a specified position in the grid.
   *
   * @param {number} row - The starting row position to check.
   * @param {number} col - The starting column position to check.
   * @param {number} colSpan - The number of columns the widget spans.
   * @param {number} rowSpan - The number of rows the widget spans.
   * @returns {boolean} `true` if the widget can be placed at the specified position;
   * otherwise, `false`.
   */
  const canPlaceWidget = (row, col, colSpan, rowSpan) => {
    for (let r = 0; r < rowSpan; r++) {
      for (let c = 0; c < colSpan; c++) {
        if (layoutMap[`${row + r},${col + c}`] !== undefined) return false;
      }
    }

    return true;
  };

  /**
   * Places a widget in the grid layout map by marking the occupied cells.
   *
   * @param {number} row - The starting row position for the widget.
   * @param {number} col - The starting column position for the widget.
   * @param {number} colSpan - The number of columns the widget spans.
   * @param {number} rowSpan - The number of rows the widget spans.
   * @param {number} widgetIndex - The index of the widget in the widgets array.
   */
  const placeWidgetInMap = (row, col, colSpan, rowSpan, widgetIndex) => {
    for (let r = 0; r < rowSpan; r++) {
      for (let c = 0; c < colSpan; c++) {
        layoutMap[`${row + r},${col + c}`] = widgetIndex;
      }
    }
    maxRow = Math.max(maxRow, row + rowSpan);
  };

  widgets.forEach((widget, index) => {
    const colSpan = Math.min(widget.colSpan, columnCount);
    const rowSpan =
      typeof widget.rowSpan === 'object'
        ? widget.rowSpan[activeBreakpoint] || widget.rowSpan.default
        : widget.rowSpan;

    let placed = false;
    let row = 0;

    while (!placed) {
      for (let col = 0; col <= columnCount - colSpan; col++) {
        if (canPlaceWidget(row, col, colSpan, rowSpan)) {
          placeWidgetInMap(row, col, colSpan, rowSpan, index);
          placed = true;
          break;
        }
      }
      row++;
    }
  });

  return { layoutMap, rowCount: maxRow };
};

/**
 * Calculates the number of columns that can fit in a container.
 *
 * @param {number} width - The width of the container in pixels.
 * @param {number} [columnWidth=DEFAULT_COLUMN_WIDTH] - The width of a single column in pixels.
 * @param {number} [maxColumns=DEFAULT_MAX_COLUMNS] - The maximum number of columns allowed.
 * @returns {number} The calculated number of columns that can fit in the container.
 */
export const calculateColumnCount = (
  width,
  columnWidth = DEFAULT_COLUMN_WIDTH,
  maxColumns = DEFAULT_MAX_COLUMNS
) => Math.min(maxColumns, Math.floor(width / columnWidth));

/**
 * Maps a layout of widgets from the layoutMap generated by `generateLayoutMap`.
 *
 * @param {Array<Object>} widgets - The array of widgets to be positioned in the grid.
 * Each widget should have properties such as `widgetComponent`, `colSpan`, and `rowSpan`.
 * @param {Object} layoutMap - The layout map object with grid coordinates as keys and
 * widget indices as values.
 * @param {number} columnCount - The number of columns available in the grid.
 * @param {number} rowCount - The total number of rows occupied by widgets in the layout.
 * @param {string} currentBreakpoint - The active breakpoint to determine rowSpan.
 * @returns {Array<Object>} An array of objects representing the layout of each widget,
 * with properties `key`, `widgetComponent`, `colSpan`, and `rowSpan`.
 */
export const mapLayout = (
  widgets,
  layoutMap,
  columnCount,
  rowCount,
  currentBreakpoint
) => {
  const finalLayout = [];
  const renderedWidgets = new Set();

  const widgetsCopy = adjustWidgets(widgets, columnCount, currentBreakpoint);

  for (let row = 0; row < rowCount; row++) {
    const { rowWidgets, emptyCells } = analyzeRow(
      layoutMap,
      widgetsCopy,
      row,
      columnCount
    );

    if (emptyCells.length > 0) {
      expandWidgets(rowWidgets, emptyCells);
    }

    addRowWidgetsToLayout(rowWidgets, renderedWidgets, finalLayout);
  }

  return finalLayout;
};

/**
 * Adjusts the widgets by calculating colSpan and rowSpan based on the current breakpoint.
 *
 * @param {Array<Object>} widgets - The array of widgets to adjust.
 * @param {number} columnCount - The total number of columns available in the grid.
 * @param {string} currentBreakpoint - The active breakpoint to determine rowSpan.
 * @returns {Array<Object>} The adjusted widgets with calculated colSpan and rowSpan.
 */
const adjustWidgets = (widgets, columnCount, currentBreakpoint) =>
  widgets.map((widget) => ({
    ...widget,
    colSpan: Math.min(widget.colSpan, columnCount),
    rowSpan:
      typeof widget.rowSpan === 'object'
        ? widget.rowSpan[currentBreakpoint] || widget.rowSpan.default
        : widget.rowSpan,
  }));

/**
 * Analyzes a single row to identify widgets and empty cells.
 *
 * @param {Object} layoutMap - The layout map object with grid coordinates as keys.
 * @param {Array<Object>} widgetsCopy - The array of adjusted widgets.
 * @param {number} row - The row index to analyze.
 * @param {number} columnCount - The total number of columns available in the grid.
 * @returns {Object} An object containing rowWidgets and emptyCells for the analyzed row.
 */
const analyzeRow = (layoutMap, widgetsCopy, row, columnCount) => {
  const rowWidgets = [];
  const emptyCells = [];

  for (let col = 0; col < columnCount; col++) {
    const widgetIndex = layoutMap[`${row},${col}`];

    if (widgetIndex !== undefined) {
      const startRow = Math.min(
        ...Object.keys(layoutMap)
          .filter((key) => layoutMap[key] === widgetIndex)
          .map((key) => Number(key.split(',')[0]))
      );

      rowWidgets.push({ widget: widgetsCopy[widgetIndex], startRow });
    } else if (widgetIndex === undefined) {
      emptyCells.push({ row, col });
    }
  }

  return { rowWidgets, emptyCells };
};

/**
 * Attempts to expand widgets (GOAL or RELEASE_NOTE) to fill empty cells.
 *
 * @param {Array<Object>} rowWidgets - The widgets present in the current row.
 * @param {Array<Object>} emptyCells - The empty cells in the current row.
 */
const expandWidgets = (rowWidgets, emptyCells) => {
  for (const { widget, startRow } of rowWidgets) {
    if (
      widget.colSpan === 1 &&
      (widget.type === 'GOAL' || widget.type === 'RELEASE_NOTE')
    ) {
      const canExpand = emptyCells.some((empty) => empty.row === startRow);

      if (canExpand) {
        widget.colSpan += 1;
        emptyCells.splice(
          emptyCells.findIndex((empty) => empty.row === startRow),
          1
        );
      }
    }
  }
};

/**
 * Adds the widgets in the current row to the final layout.
 *
 * @param {Array<Object>} rowWidgets - The widgets present in the current row.
 * @param {Set} renderedWidgets - A set of already rendered widgets.
 * @param {Array<Object>} finalLayout - The final layout to update.
 */
const addRowWidgetsToLayout = (rowWidgets, renderedWidgets, finalLayout) => {
  rowWidgets.forEach(({ widget }) => {
    if (!renderedWidgets.has(widget.id)) {
      renderedWidgets.add(widget.id);
      finalLayout.push({
        key: widget.id,
        widgetComponent: widget.widgetComponent,
        colSpan: widget.colSpan,
        rowSpan: widget.rowSpan,
      });
    }
  });
};
