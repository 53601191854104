import CustomButton from 'components/partials/custom-button/custom-button';

const GoalContactkItem = () => (
  <div className="bg-gray-00 rounded-md w-72 h-40 flex flex-col items-center justify-center border border-navy-01">
    <div className="font-bold">Are your goals missing?</div>
    <p className="mb-4 font-medium">Contact your client success team.</p>
    <CustomButton variant="primary" size="sm">
      Speak with us
    </CustomButton>
  </div>
);

export default GoalContactkItem;
